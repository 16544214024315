<template>
  <div id="app">
    <el-container>
      <el-main>
        <div class="h1">Create a Fulfilling Life</div>
        <div class="h2">Creating a fulfilling life means aligning your behaviors with your values. Think of values as principles, qualities, or beliefs that hold great significance and provide meaning to your life. Complete the exercise below to get started.</div>
        <el-row :gutter="20">
          <el-col  :xs="24" :sm="12" :md="8" :lg="6" :xl="4" v-for="(item, index) in nowList" :key="index">
            <el-card class="item" :class="computedActive(item.id)" shadow="hover">
              <div @click="itemClickFunc(item.id)">
                <div class="te">{{ item.titleEn }}</div>
                <div class="tc">{{ item.titleCn }}</div>
                <div class="be">{{ item.briefEn }}</div>
                <div class="bc">{{ item.briefCn }}</div>
              </div>
            </el-card>
          </el-col>
        </el-row>
      </el-main>
    </el-container>
    <div class="bottom not">
      <div class="tips">
        <div class="t">STEP 1</div>
        <div class="b">Select your 20 most important values. Then, click <i class="el-icon-d-arrow-right"></i>.</div>
      </div>
      <div class="btns">
        <el-button type="primary" icon="el-icon-d-arrow-left" circle></el-button>
        <span class="num">0</span>
        <el-button type="primary" icon="el-icon-d-arrow-right" circle></el-button>
      </div>
    </div>
    <div class="bottom">
      <div class="tips">
        <div class="t">STEP {{ nowNum }}</div>
        <div class="b">Select your {{ nowSelNum }} most important values. Then, click "<i class="el-icon-d-arrow-right"></i>".</div>
      </div>
      <div class="btns">
        <el-button type="primary" icon="el-icon-d-arrow-left" circle :disabled="prevStatus" @click="prevClick"></el-button>
        <span class="num">{{ nowIds.length }}</span>
        <el-button type="primary" icon="el-icon-d-arrow-right" circle :disabled="nextStatus" @click="nextClick"></el-button>
      </div>
    </div>

    <el-drawer
      size="100%"
      title="Your Results"
      :visible.sync="drawer"
      :direction="'rtl'">
      <div class="submit">
          <el-main>
            <div class="h2">
              Below are your top 5 and top 10 values. Living inauthentically, by not aligning your behaviors with your values, can lead to stress, purposelessness, and dissatisfaction. Use these values as a guide for conversations with your therapist or coach, or take some time to reflect on how well your daily behaviors align with your top values. Then, consider changes you may want to make to live more authentically. This will help you live a more fulfilling and meaningful life.<br>
              Need help making changes? Reach out for a free therapy consultation.
            </div>
            <div class="h1">TOP 5 VALUES</div>
            <el-row :gutter="20">
              <el-col  :xs="24" :sm="12" :md="8" :lg="6" :xl="4" v-for="(item, index) in top5" :key="index">
                <el-card class="item">
                  <div >
                    <div class="te">{{ item.titleEn }}</div>
                    <div class="tc">{{ item.titleCn }}</div>
                    <div class="be">{{ item.briefEn }}</div>
                    <div class="bc">{{ item.briefCn }}</div>
                  </div>
                </el-card>
              </el-col>
          </el-row>
        </el-main>
        <div class="h1">TOP 10 VALUES</div>
        <el-main>
            <el-row :gutter="20">
              <el-col  :xs="24" :sm="12" :md="8" :lg="6" :xl="4" v-for="(item, index) in top10" :key="index">
                <el-card class="item">
                  <div >
                    <div class="te">{{ item.titleEn }}</div>
                    <div class="tc">{{ item.titleCn }}</div>
                    <div class="be">{{ item.briefEn }}</div>
                    <div class="bc">{{ item.briefCn }}</div>
                  </div>
                </el-card>
              </el-col>
          </el-row>
        </el-main>
      </div>
    </el-drawer>
  </div>
</template>

<script>
export default {
  name: 'App',
  data(){
    return {
      drawer:false,
      dataList:[
        {"id":1,"titleCn":"接受","titleEn":"ACCEPTANCE","briefCn":"我的身份被接受","briefEn":"to be accepted as I am"},
        {"id":2,"titleCn":"准确度","titleEn":"ACCURACY","briefCn":"准确地表达自己的观点和信念","briefEn":"to be accurate in my opinions and beliefs"},
        {"id":3,"titleCn":"成功","titleEn":"ACHIEVEMENT","briefCn":"取得重大的成绩","briefEn":"to have important accomplishments"},
        {"id":4,"titleCn":"冒险","titleEn":"ADVENTURE","briefCn":"有新的和令人兴奋的经历","briefEn":"to have new and exciting experiences"},
        {"id":5,"titleCn":"吸引力","titleEn":"ATTRACTIVENESS","briefCn":"外表比较有吸引力","briefEn":"to be physically attractive"},
        {"id":6,"titleCn":"权威","titleEn":"AUTHORITY","briefCn":"掌控他人以及负责","briefEn":"to be in charge of and responsible for others"},
        {"id":7,"titleCn":"自律","titleEn":"AUTONOMY","briefCn":"独立自主","briefEn":"to be self-determined and independent"},
        {"id":8,"titleCn":"美好","titleEn":"BEAUTY","briefCn":"去欣赏周边事物的美好","briefEn":"to appreciate beauty around me"},
        {"id":9,"titleCn":"关爱","titleEn":"CARING","briefCn":"可以关爱他人","briefEn":"to take care of others"},
        {"id":10,"titleCn":"挑战","titleEn":"CHALLENGE","briefCn":"可以接受挑战及解决问题","briefEn":"to take on difficult tasks and problems"},
        {"id":11,"titleCn":"变化","titleEn":"CHANGE","briefCn":"过一种充满变化和多样性的生活","briefEn":"to have a life full of change and variety"},
        {"id":12,"titleCn":"舒适度","titleEn":"COMFORT","briefCn":"过一种愉快且舒适的生活","briefEn":"to have a pleasant and comfortable life"},
        {"id":13,"titleCn":"承诺","titleEn":"COMMITMENT","briefCn":"做出持久有意义的承诺","briefEn":"to make enduring, meaningful commitments"},
        {"id":14,"titleCn":"同理心","titleEn":"COMPASSION","briefCn":"可以感受他人的关怀并付出行动","briefEn":"to feel and act on concern for others"},
        {"id":15,"titleCn":"贡献","titleEn":"CONTRIBUTION","briefCn":"在这个世界里可以做出持久的贡献","briefEn":"to make a lasting contribution in the world"},
        {"id":16,"titleCn":"合作","titleEn":"COOPERATION","briefCn":"与他人合作","briefEn":"to work collaboratively with others"},
        {"id":17,"titleCn":"礼貌谦逊","titleEn":"COURTESY","briefCn":"对待他人谦逊体贴有礼貌","briefEn":"to be considerate and polite toward others"},
        {"id":18,"titleCn":"创新","titleEn":"CREATIVITY","briefCn":"有新的创意及想法","briefEn":"to have new and original ideas"},
        {"id":19,"titleCn":"可靠度","titleEn":"DEPENDABILITY","briefCn":"可靠可信","briefEn":"to be reliable and trustworthy"},
        {"id":20,"titleCn":"责任","titleEn":"DUTY","briefCn":"履行自己的责任和义务","briefEn":"to carry out my duties and obligations"},
        {"id":21,"titleCn":"生态","titleEn":"ECOLOGY","briefCn":"与环境和谐共处","briefEn":"to live in harmony with the environment"},
        {"id":22,"titleCn":"兴奋","titleEn":"EXCITEMENT","briefCn":"对生活充满激情","briefEn":"to have a life full of thrills and stimulation"},
        {"id":23,"titleCn":"诚信","titleEn":"FAITHFULNESS","briefCn":"在人际交往中诚实守信","briefEn":"to be loyal and true in relationships"},
        {"id":24,"titleCn":"名望","titleEn":"FAME","briefCn":"为人所知和认可","briefEn":"to be known and recognized"},
        {"id":25,"titleCn":"家庭","titleEn":"FAMILY","briefCn":"拥有幸福有爱的家庭","briefEn":"to have a happy, loving family"},
        {"id":26,"titleCn":"健身","titleEn":"FITNESS","briefCn":"身体健康且强壮","briefEn":"to be physically fit and strong"},
        {"id":27,"titleCn":"灵活度","titleEn":"FLEXIBILITY","briefCn":"可以很容易的适应新形势","briefEn":"to adjust to new circumstances easily"},
        {"id":28,"titleCn":"宽恕","titleEn":"FORGIVENESS","briefCn":"可以宽恕别人","briefEn":"to be forgiving of others"},
        {"id":29,"titleCn":"友谊","titleEn":"FRIENDSHIP","briefCn":"有亲密且支持的朋友","briefEn":"to have close, supportive friends"},
        {"id":30,"titleCn":"有趣","titleEn":"FUN","briefCn":"爱玩且有趣","briefEn":"to play and have fun"},
        {"id":31,"titleCn":"奉献精神","titleEn":"GENEROSITY","briefCn":"可以把拥有的给予别人","briefEn":"to give what I have to others"},
        {"id":32,"titleCn":"真诚","titleEn":"GENUINENESS","briefCn":"以一种真诚的态度行事","briefEn":"to act in a manner that is true to who I am"},
        {"id":33,"titleCn":"信仰","titleEn":"GODS  WILL","briefCn":"遵从神的旨意","briefEn":"to seek and obey the will of God"},
        {"id":34,"titleCn":"成长","titleEn":"GROWTH","briefCn":"不断的改变和成长","briefEn":"to keep changing and growing"},
        {"id":35,"titleCn":"健康","titleEn":"HEALTH","briefCn":"拥有健康的身体","briefEn":"to be physically well and healthy"},
        {"id":36,"titleCn":"助人","titleEn":"HELPFULNESS","briefCn":"可以帮助他人","briefEn":"to be helpful to others"},
        {"id":37,"titleCn":"诚实","titleEn":"HONESTY","briefCn":"诚实守信","briefEn":"to be honest and truthful"},
        {"id":38,"titleCn":"希望","titleEn":"HOPE","briefCn":"对于前景保持积极乐观的态度","briefEn":"to maintain a positive and optimistic outloo"},
        {"id":39,"titleCn":"谦卑","titleEn":"HUMILITY","briefCn":"谦逊 谦卑","briefEn":"to be modest and unassuming"},
        {"id":40,"titleCn":"幽默","titleEn":"HUMOR","briefCn":"以幽默的态度来面对世界","briefEn":"to see the humorous side of myself and the world"},
        {"id":41,"titleCn":"独立","titleEn":"INDEPENDENCE","briefCn":"独立自由可以摆脱对他人的依赖","briefEn":"to be free from dependence on others"},
        {"id":42,"titleCn":"勤劳","titleEn":"INDUSTRY","briefCn":"努力工作改变现在生活","briefEn":"to work hard and well at my life tasks"},
        {"id":43,"titleCn":"内心平静","titleEn":"INNER PEACE","briefCn":"追求内心的平静","briefEn":"to experience personal peace"},
        {"id":44,"titleCn":"亲密","titleEn":"INTIMACY","briefCn":"可以与别人分享最深处的亲密","briefEn":"to share my innermost experiences with others"},
        {"id":45,"titleCn":"公正","titleEn":"JUSTICE","briefCn":"促使人人享有公平和平等的待遇","briefEn":"to promote fair and equal treatment for all"},
        {"id":46,"titleCn":"知识","titleEn":"KNOWLEDGE","briefCn":"可以不断的学习做出有价值的贡献","briefEn":"to learn and contribute valuable knowledge"},
        {"id":47,"titleCn":"休闲","titleEn":"LEISURE","briefCn":"可以花费时间放松和享受","briefEn":"to take time to relax and enjoy"},
        {"id":48,"titleCn":"深爱","titleEn":"LOVED","briefCn":"被人深爱","briefEn":"to be loved by those close to me"},
        {"id":49,"titleCn":"爱意","titleEn":"LOVING","briefCn":"给别人爱","briefEn":"to give love to others"},
        {"id":50,"titleCn":"掌握","titleEn":"MASTERY","briefCn":"能够胜任自己的生活","briefEn":"to be competent in my everyday activities"},
        {"id":51,"titleCn":"正念","titleEn":"MINDFULNESS","briefCn":"对于现在的生活有正确的意识","briefEn":"to live conscious and mindful of the present moment"},
        {"id":52,"titleCn":"缓解","titleEn":"MODERATION","briefCn":"可以找到中间地带 以避免过度行为","briefEn":"to avoid excesses and find a middle ground"},
        {"id":53,"titleCn":"一夫一妻制","titleEn":"M0NOGAMY","briefCn":"拥有唯一亲密充满爱的关系","briefEn":"to have one close loving relationship"},
        {"id":54,"titleCn":"不墨守成规","titleEn":"NON-CONFORMITY","briefCn":"拥有批判性思维可以质疑且规划权威","briefEn":"to question and challenge authority and norms"},
        {"id":55,"titleCn":"养育","titleEn":"NURTURANCE","briefCn":"可以照顾及养育他人","briefEn":"to take care of and nurture others"},
        {"id":56,"titleCn":"开放","titleEn":"OPENNESS","briefCn":"可以接受且体验新鲜事物","briefEn":"to be open to new experiences, ideas, and options"},
        {"id":57,"titleCn":"井然有序","titleEn":"ORDER","briefCn":"过着竟然有序的生活","briefEn":"to have a life that is well-ordered and organized"},
        {"id":58,"titleCn":"激情","titleEn":"PASSION","briefCn":"对人或者事物有着深刻的感情","briefEn":"to have deep feelings about ideas, activities, or people"},
        {"id":59,"titleCn":"快乐","titleEn":"PLEASURE","briefCn":"感觉很好","briefEn":"to feel good"},
        {"id":60,"titleCn":"受欢迎","titleEn":"POPULARITY","briefCn":"受到多人的喜爱","briefEn":"to be well-liked by many people"},
        {"id":61,"titleCn":"力量","titleEn":"POWER","briefCn":"可以控制他人","briefEn":"to have control over others"},
        {"id":62,"titleCn":"目的","titleEn":"PURPOSE","briefCn":"在自己的生活中有方向且有意义","briefEn":"to have meaning and direction in my life"},
        {"id":63,"titleCn":"合理性","titleEn":"RATIONALITY","briefCn":"以理性和逻辑为指导","briefEn":"to be guided by reason and logic"},
        {"id":64,"titleCn":"真理","titleEn":"REALISM","briefCn":"实事求是的看待恩题解决问题","briefEn":"to see and act realistically and practically"},
        {"id":65,"titleCn":"责任","titleEn":"RESPONSIBILITY","briefCn":"可以做决策并且执行负责任","briefEn":"to make and carry out responsible decisions"},
        {"id":66,"titleCn":"冒险","titleEn":"RISK","briefCn":"敢于冒险","briefEn":"to take risks and chances"},
        {"id":67,"titleCn":"浪漫的","titleEn":"ROMANCE","briefCn":"追求生命中强烈且令人兴奋的爱","briefEn":"to have intense, exciting love in my life"},
        {"id":68,"titleCn":"自我接受","titleEn":"SEL F-ACCEPTANCE","briefCn":"可以接受现在的自己","briefEn":"to accept myself as I am"},
        {"id":69,"titleCn":"安全","titleEn":"SAFETY","briefCn":"生理需-安然无恙","briefEn":"to be safe and secure"},
        {"id":70,"titleCn":"自我控制","titleEn":"SELF-CONTROL","briefCn":"在行动中可以约束自己","briefEn":"to be disciplined in my own actions"},
        {"id":71,"titleCn":"自尊","titleEn":"SEL F-ESTEEM","briefCn":"自我感觉良好","briefEn":"to feel good about myself"},
        {"id":72,"titleCn":"自知","titleEn":"SELF-KNOWLEDGE","briefCn":"对自己有一个深刻且诚实的认知","briefEn":"to have a deep and honest understanding of myself"},
        {"id":73,"titleCn":"服务","titleEn":"SERVICE","briefCn":"可以为他人服务","briefEn":"to be of service to others"},
        {"id":74,"titleCn":"性","titleEn":"SEXUALITY","briefCn":"有一个令人满意且积极的性生活","briefEn":"to have an active and satisfying sex life"},
        {"id":75,"titleCn":"简朴","titleEn":"SIMPLICITY","briefCn":"以最小的需求过最简朴的生活","briefEn":"to live life simply, with minimal needs"},
        {"id":76,"titleCn":"孤独","titleEn":"SOLITUDE","briefCn":"孤芳自赏","briefEn":"to have time and space where I can be apart from others"},
        {"id":77,"titleCn":"灵性","titleEn":"SPIRITUALITY","briefCn":"在精神上的成熟及成长","briefEn":"to grow and mature spiritually"},
        {"id":78,"titleCn":"稳定","titleEn":"STABILITY","briefCn":"拥有一种相当稳定的生活","briefEn":"to have a life that stays fairly consistent"},
        {"id":79,"titleCn":"容忍","titleEn":"TOLERANCE","briefCn":"接受及尊重和自己的不同","briefEn":"to accept and respect those who differ from me"},
        {"id":80,"titleCn":"传统","titleEn":"TRADITION","briefCn":"遵循和尊重传统","briefEn":"to follow respected patterns of the past"},
        {"id":81,"titleCn":"美德","titleEn":"VIRTUE","briefCn":"过一种道德上纯洁而美好的生活","briefEn":"to live a morally pure and excellent life"},
        {"id":82,"titleCn":"财富","titleEn":"WEALTH","briefCn":"拥有大量的财富","briefEn":"to have plenty of money"},
        {"id":83,"titleCn":"世界和平","titleEn":"WORLD PEACE","briefCn":"通过自己的努力来促进世界和平","briefEn":"to work to promote peace in the world"}
      ],
      nowList:[],
      nowNum:1,
      nowSelNum:20,
      nowIds:[],
      sel1Num:20,
      sel2Num:10,
      sel3Num:5,
      sel1Ids:[],
      sel2Ids:[],
      sel3Ids:[],
      top5:[],
      top10:[],
    }
  },
  computed:{
    nextStatus(){
      if(!this.nowIds.length){
        return true
      }
      if(this.nowNum == 1 && this.nowIds.length == this.sel1Num){
        return false
      }
      if(this.nowNum == 2  && this.nowIds.length == this.sel2Num){
        return false
      }
      if(this.nowNum == 3  && this.nowIds.length == this.sel3Num){
        return false
      }
      return true
    },
    prevStatus(){
      if(this.nowNum > 1){
        return false
      }
      return true
    }
  },
  created(){
    this.nowList = JSON.parse(JSON.stringify(this.dataList));
  },
  methods:{
    itemClickFunc(id){
      let nowIds = [];
      if(this.nowNum == 1 ){
        nowIds = JSON.parse(JSON.stringify(this.sel1Ids))
      }
      if(this.nowNum == 2 ){
        nowIds = JSON.parse(JSON.stringify(this.sel2Ids))
      }
      if(this.nowNum == 3 ){
        nowIds = JSON.parse(JSON.stringify(this.sel3Ids))
      }
      if(nowIds.includes(id)){
         let newIds = []
         nowIds.forEach(nowId => {
          if(nowId != id){
            newIds.push(nowId)
          }
         });
         this.nowIds = newIds
      }else{
        nowIds.push(id)
        this.nowIds = nowIds
      }
      if(this.nowNum == 1 ){
        this.sel1Ids = JSON.parse(JSON.stringify(this.nowIds))
      }
      if(this.nowNum == 2 ){
        this.sel2Ids = JSON.parse(JSON.stringify(this.nowIds))
      }
      if(this.nowNum == 3 ){
        this.sel3Ids = JSON.parse(JSON.stringify(this.nowIds))
      }

    },
    computedActive(id){
      if(this.nowIds.includes(id)){
        return "active"
      }
      return  ""
    },
    getSelList(ids){
      let list = []
      this.dataList.forEach(item => {
        ids.forEach(id => {
          if(item.id == id){
            list.push(item)
          }
        });
      });
      return list;
    },
    nextClick(){
      if(this.nowNum == 1){
        let ids = this.getNowIdsListBySelIds(JSON.parse(JSON.stringify(this.sel2Ids)))
        this.sel2Ids = ids
        this.nowNum = 2
        this.nowIds = ids
        this.nowSelNum = this.sel2Num
        this.nowList = this.getSelList(this.sel1Ids)
      }else if(this.nowNum == 2){
        let ids = this.getNowIdsListBySelIds(JSON.parse(JSON.stringify(this.sel3Ids)))
        this.sel3Ids = ids
        this.nowNum = 3
        this.nowIds = ids
        this.nowSelNum = this.sel3Num
        this.nowList = this.getSelList(this.sel2Ids)
      }else if(this.nowNum == 3){
        this.top5 = this.getSelList(this.sel3Ids)
        this.top10 = this.getSelList(this.sel2Ids)
        this.drawer = true
      }
    },
    prevClick(){
      if(this.nowNum == 2){
        this.nowNum = 1
        this.nowIds = JSON.parse(JSON.stringify(this.sel1Ids))
        this.nowSelNum = this.sel1Num
        this.nowList = JSON.parse(JSON.stringify(this.dataList))
      }else if(this.nowNum == 3){
        this.nowNum = 2
        this.nowIds = JSON.parse(JSON.stringify(this.sel2Ids))
        this.nowSelNum = this.sel2Num
        this.nowList = this.getSelList(this.sel1Ids)
      }
    },
    getNowIdsListBySelIds(selIds){
      let ids = []
      selIds.forEach(selId => {
        this.nowIds.forEach(nowId => {
          if(nowId == selId){
            ids.push(selId)
          }
        });
      });
      return ids
    }
  }
}
</script>

<style>
*{
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
html,body{
  font-family:Arial,Helvetica,sans-serif;
  color: #333;
  font-size: 14px;
}

.submit{
  height: calc(100VH - 75px);
  overflow-y: auto;
  width: 100%;
}


.h1{
  color: #333;
  font-size: 52px;
  font-style: normal;
  line-height: 1.5;
  text-align: center;
  vertical-align: baseline;
  margin-bottom: 20px;
}

.h2{
  color: #333;
  font-size: 24px;
  font-style: normal;
  line-height: 1.5;
  text-align: center;
  vertical-align: baseline;
  margin-bottom: 24px;

}

.item {
  min-height: 150px;
  text-align: center;
  color: #333;
  border-radius: 4px;
  margin-bottom: 20px;
  cursor: pointer;
}
.item.active{
  background: #409EFF;
}
.te{
  font-size: 18px;
}
.tc{
  font-size: 18px;
  margin-bottom: 10px;
}
.be{
  font-size: 13px;
}
.bc{
  font-size: 13px;
}

.item.active .te{
  font-size: 18px;
  color: #fff;
}
.item.active .tc{
  font-size: 18px;
  margin-bottom: 10px;
  color: #fff;
}
.item.active .be{
  font-size: 13px;
  color: #fff;
}
.item.active .bc{
  font-size: 13px;
  color: #fff;
}

.bottom{
  z-index: 99;
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  background: #fff;
  box-shadow: 0 -8px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 15px;
}
.bottom.not{
  z-index: 98;
  position: relative;
  opacity: 0;
}
.tips{
  min-height: 80px;
  padding: 10px;
  flex: 1;
  font-size: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center
}

.tips .b{
  color: #666;
  font-size: 14px;
}

.btns{
  min-height: 80px;
  justify-content: end;
  padding: 5px;
  display: flex;
  align-items: center;
  flex-shrink: 0;
}
.num{
  width: 60px;
  font-size: 30px;
  text-align: center;
}
</style>
